import { __assign } from "tslib";
import { FareFeaturePaymentType } from '@websky/graphql';
import DesktopFlightRouteModal from './components/DesktopFlightModalContent/DesktopFlightModalContent';
import FareGroup from './components/FareGroup/FareGroup';
import { FareLockIcon, icons } from './components/icons';
import PassengerFormFields from './components/PassengerForm/FormFields/FormFields';
import { renderCheckoutLinksLabel } from './components/InfoLinksLabel/InfoLinksLabel';
import OrderInsuranceBlockSlotUpsale from '@websky/core/src/Checkout/components/Checkout/OrderInsuranceBlockSlot/OrderInsuranceBlockSlot.upsale';
import CheckoutAdditionalService from '@websky/core/src/CheckoutAdditionalService/CheckoutAdditionalServiceV2/CheckoutAdditionalServiceV2';
import Locations from './components/Schedule/Locations/Locations';
export var renders = __assign(__assign({}, icons), { DesktopFlightRouteModal: DesktopFlightRouteModal, FareGroupGrid: FareGroup, RenderFareLockIcon: FareLockIcon, PassengerFormFields: PassengerFormFields, Included: icons[FareFeaturePaymentType.Free], Paid: icons[FareFeaturePaymentType.Pay], renderCheckoutLinksLabel: renderCheckoutLinksLabel, CheckoutInsurance: OrderInsuranceBlockSlotUpsale, CheckoutAdditionalService: CheckoutAdditionalService, ScheduleLocations: Locations });
