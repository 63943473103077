import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@websky/core/src/Link';
import { getLocale } from '@websky/core/src/utils';
import { Locale } from '@websky/core/src/types';
export var renderCheckoutLinksLabel = function (companyInfo) {
    var t = useTranslation('Checkout').t;
    var currentLocale = getLocale();
    var linkLocale = currentLocale === Locale.Russian ? '' : currentLocale;
    var consentLink = "https://www.severstal-avia.ru/info/konfidentsialnost".concat(linkLocale ? "/".concat(linkLocale) : '', "/soglasie-pdn.php");
    return (React.createElement(React.Fragment, null,
        t('I authorize the processing and storage of my personal data. I hereby confirm that if I issue air tickets/additional services to third parties, I provide personal data with their consent.'),
        "\u00A0",
        React.createElement(Link, { target: '_blank', action: consentLink }, t('Read the consent')),
        "."));
};
