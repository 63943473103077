import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { InsurancePage } from '../InsurancePage/InsurancePage';
import FullscreenDialogClose from '../../../FullscreenDialog/components/Close/Close';
import Toolbar from '../../../Toolbar';
export var InsurancePageModal = function (_a) {
    var className = _a.className, totalPrice = _a.totalPrice, onConfirm = _a.onConfirm, onClose = _a.onClose, props = __rest(_a, ["className", "totalPrice", "onConfirm", "onClose"]);
    var t = useTranslation('InsurancePage').t;
    var theme = useTheme('InsurancePage').InsurancePageModal;
    return (React.createElement("div", { className: cn(className, theme.container) },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__wrapper },
                React.createElement("div", { className: theme.title }, t('Choice of insurance')),
                React.createElement(FullscreenDialogClose, { text: t('Back to services'), onClose: onClose }))),
        React.createElement(InsurancePage, __assign({}, props)),
        React.createElement(Toolbar, { innerClassName: theme.footer, totalPrice: totalPrice, onButtonClick: onConfirm, onBackButtonClick: onClose })));
};
