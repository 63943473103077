import * as React from 'react';
import { Checkin } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import { HashRouter } from 'react-router-dom';
import theme from '../../theme/theme';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
import { renders } from '../../renders';
import { RenderersProvider } from '@websky/core/src/renderProps';
var CheckinComponent = function () {
    return (React.createElement(HashRouter, null,
        React.createElement(RenderersProvider, { value: renders },
            React.createElement(ThemeProvider, { value: theme },
                React.createElement(Head, null),
                React.createElement(Checkin, null)))));
};
export default CheckinComponent;
