import { __assign } from "tslib";
import * as React from 'react';
import { sortLocationsByName } from '@websky/core/src/Schedule/utils';
import LocationsRoot from '@websky/core/src/Schedule/components/Locations/Locations';
var PRIORITY_CITIES_IATA = ['CEE'];
var Locations = function (props) {
    var locationsSorting = function (a, b) {
        if (PRIORITY_CITIES_IATA.includes(a.iata)) {
            return -1;
        }
        else if (PRIORITY_CITIES_IATA.includes(b.iata)) {
            return 1;
        }
        return sortLocationsByName(a, b);
    };
    return React.createElement(LocationsRoot, __assign({}, props, { locationsSorting: locationsSorting }));
};
export default Locations;
